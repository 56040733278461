<template>
  <SbComponent ref="blokRef" v-bind="{ ...$props, ...$attrs }"></SbComponent>
</template>

<script setup lang="ts">
import { ref, defineAsyncComponent, hydrateOnVisible } from 'vue'
import type { SbBlokData } from '@storyblok/vue'

export interface SbComponentProps {
  blok: SbBlokData
}
const props = defineProps<SbComponentProps>()

const blokRef = ref()

defineExpose({
  value: blokRef,
})

const SbComponent = defineAsyncComponent({
  loader: () => {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (props.blok.component) {
      case 'Accordion':
        return import('./Accordion.vue')
      case 'AccordionEntry':
        return import('./AccordionEntry.vue')
      case 'BrandList':
        return import('./BrandList.vue')
      case 'ButtonBox':
        return import('./ButtonBox.vue')
      case 'ClickableImage':
        return import('./ClickableImage.vue')
      case 'CmsButton':
        return import('./CmsButton.vue')
      case 'CmsHeadline':
        return import('./CmsHeadline.vue')
      case 'CmsImage':
        return import('./CmsImage.vue')
      case 'CmsLink':
        return import('./CmsLink.vue')
      case 'CmsSlide':
        return import('./CmsSlide.vue')
      case 'CmsText':
        return import('./CmsText.vue')
      case 'CmsVideo':
        return import('./CmsVideo.vue')
      case 'ContentTriple':
        return import('./ContentTriple.vue')
      case 'DesignerDouble':
        return import('./DesignerDouble.vue')
      case 'DesignerHighlight':
        return import('./DesignerHighlight.vue')
      case 'DesignerShifted':
        return import('./DesignerShifted.vue')
      case 'DetailImage':
        return import('./DetailImage.vue')
      case 'FallbackComponent':
        return import('./FallbackComponent.vue')
      case 'FooterColumn':
        return import('./FooterColumn.vue')
      case 'Grid':
        return import('./Grid.vue')
      case 'HearingTest':
        return import('./HearingTest.vue')
      case 'Hero':
        return import('./Hero.vue')
      case 'IconParagraph':
        return import('./IconParagraph.vue')
      case 'ImageParagraph':
        return import('./ImageParagraph.vue')
      case 'ImageSlider':
        return import('./ImageSlider.vue')
      case 'ImageSliderSlide':
        return import('./ImageSliderSlide.vue')
      case 'Infographic':
        return import('./Infographic.vue')
      case 'LogoSlider':
        return import('./LogoSlider.vue')
      case 'NdlList':
        return import('./NdlList.vue')
      case 'Newsletter':
        return import('./Newsletter.vue')
      case 'OneTrustCookieList':
        return import('./OneTrustCookieList.vue')
      case 'OneTrustShowSettings':
        return import('./OneTrustShowSettings.vue')
      case 'Page':
        return import('./Page.vue')
      case 'Paragraph':
        return import('./Paragraph.vue')
      case 'ParagraphVideo':
        return import('./ParagraphVideo.vue')
      case 'PrescriptionLoader':
        return import('./PrescriptionLoader.vue')
      case 'Product':
        return import('./Product.vue')
      case 'ProductSlider':
        return import('./ProductSlider.vue')
      case 'ProductSliderCarousel':
        return import('./ProductSliderCarousel.vue')
      case 'ProductWrapper':
        return import('./ProductWrapper.vue')
      case 'RichTextHtmlRenderer':
        return import('./RichTextHtmlRenderer.vue')
      case 'RichTextRenderer':
        return import('./RichTextRenderer')
      case 'ScrollableLinkList':
        return import('./ScrollableLinkList.vue')
      case 'ShopableImage':
        return import('./ShopableImage.vue')
      case 'SlideShow':
        return import('./SlideShow.vue')
      case 'Story':
        return import('./Story.vue')
      case 'StoryblokLink':
        return import('./StoryblokLink.vue')
      case 'Teaser':
        return import('./Teaser.vue')
      case 'TeaserCard':
        return import('./TeaserCard.vue')
      case 'VideoEmbed':
        return import('./VideoEmbed.vue')
      case 'VideoPlayer':
        return import('./VideoPlayer.vue')
      case 'Vimeo':
        return import('./Vimeo.vue')
      case 'Youtube':
        return import('./Youtube.vue')
    }

    return import('./FallbackComponent.vue')
  },

  hydrate: hydrateOnVisible(),
})
</script>
